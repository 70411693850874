import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { Collapse } from "reactstrap";
import AnimationsCSS from "./Annotation.module.css";
import { BsFillRecord2Fill } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import View3D from "@obsidian/view3d";
import { view3DContext } from "../../../../../../components/HomeCanvas";
import { AddAnnotationLabel } from "@obsidian/view3d";
function Annotation({ view3dInstance = [] }) {
  window.annotationView = view3dInstance;
  const [isOpen, setIsOpen] = useState(true);
  const [annotationList, setAnnotationList] = useState([]);
  const view3D = useContext(view3DContext);

  useEffect(() => {
    console.log(view3D?.annotation.list);
    const handleDoubleClick = (event) => {
      console.log("double click");
      setTimeout(() => {
        const newAnnotationList = view3D?.annotation.list.map(
          (annotation, index) => {
            return {
              index: index + 1,
              name: annotation.element.querySelector(
                ".view3d-annotation-tooltip"
              ).innerHTML,
            };
          }
        );
        console.log(newAnnotationList);
        console.log(view3D?.annotation.list);
        if (annotationList.length !== newAnnotationList.length) {
          setAnnotationList(newAnnotationList);
        }
      }, 100);
    };
    console.log("Update");
    const newAnnotationList = view3D?.annotation.list.map(
      (annotation, index) => {
        return {
          index: index + 1,
          name: annotation.element.querySelector(".view3d-annotation-tooltip")
            .innerHTML,
        };
      }
    );
    if (annotationList.length !== newAnnotationList.length) {
      setAnnotationList(newAnnotationList);
    }
    window.handleDoubleClick = handleDoubleClick;
    document.addEventListener("dblclick", handleDoubleClick);

    return () => {
      document.removeEventListener("dblclick", handleDoubleClick);
    };
  }, [annotationList, view3D?.annotation.list]); // Add annotationList as a dependency to ensure useEffect runs when it changes

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={AnimationsCSS.animations}>
      <div className={AnimationsCSS.textandcaret}>
        <div className={AnimationsCSS.animationstext}>Annotations</div>
        <div>
          <img src="/images/CaretDown.png" alt="" onClick={toggle} />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        {annotationList.map((annotation, index) => (
          <AnnotationLabel
            key={index}
            index={annotation.index}
            name={annotation.name}
          />
        ))}
      </Collapse>
      <button
        className={`btn btn-outline-primary ${AnimationsCSS.exportanimationsbutton}`}
        onClick={() => {

          const annotationData = ExportAnnotationData(view3D);
          const link = document.createElement("a");
          document.body.appendChild(link);
          const dataBlob = new Blob([JSON.stringify(annotationData)], {
            type: "application/json",
          });
          link.style.display = "none";
          link.href = URL.createObjectURL(dataBlob);
          link.download = "model-annotation.json";
          link.click();
          document.body.removeChild(link);
          const animationData = ExportAnimationData(view3D);
          console.log(animationData.length);

          if (animationData.length > 0) {
            const link = document.createElement("a");
            const dataBlob = new Blob([JSON.stringify(animationData)], {
              type: "application/json",
            });
            document.body.appendChild(link);
            link.href = URL.createObjectURL(dataBlob);
            link.download = "model-animation.json";
            link.click();
            document.body.removeChild(link);

          }

        }}
      >
        Export
      </button>
    </div>
  );
}
const AnnotationLabel = ({ index, name }) => {
  const view3D = useContext(view3DContext);
  const input = useRef();
  useEffect(() => {
    input.current.value = name;
    input.current.addEventListener("change", () => {
      
    });
  }, [input.current]);
  return (
    <>
      <div className="annotationlabel">
        <div>{index}</div>
        <div className="inputlabelcontainer">
          <input
            ref={input}
            type="text"
            className="form-control"
            style={{ width: "50%" }}
            placeholder="Name"
            onChange={(evt) => {
              AddAnnotationLabel(view3D, evt.target.value, index - 1);
            }}
          />
        </div>
      </div>
    </>
  );
};

const ExportAnnotationData = (view3D) => {
  console.log(view3D.annotation.toJSON());
  return view3D.annotation.toJSON();
};
const ExportAnimationData = (view3D) => {
  return view3D.animator.clips.map((value) => value.toJSON())
}

export default Annotation;
