import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Collapse } from "reactstrap";
import AnimationsCSS from "./Animations.module.css";
import { BsFillRecord2Fill } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";

import { FaPlayCircle } from "react-icons/fa";
import {
  StopRecordingAnimations,
  StartRecordingAnimations,
} from "@obsidian/view3d";
import { view3DContext } from "../../../../../../components/HomeCanvas";

function Animations(args) {
  const [isOpen, setIsOpen] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);
  const view3D = useContext(view3DContext);
  const clipName = view3D.animator.clips.length == 0 ? "Idle" : view3D.animator.clips[0].name;
  const [activeDropdownName, setActiveDropdownName] = useState(
    clipName
  );
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className={AnimationsCSS.animations}>
      <div className={AnimationsCSS.textandcaret}>
        <div className={AnimationsCSS.animationstext}>Animations</div>
        <div>
          <img src="/images/CaretDown.png" alt="" onClick={toggle} />
        </div>
      </div>
      <Collapse isOpen={isOpen} {...args}>
        <div className={AnimationsCSS.animationspanel}>
          <div className={AnimationsCSS.recordanimationsbutton}>
            <button
              className={`btn btn-outline-secondary recordbuttoncolor`}
              onClick={() =>
                setIsRecording((prev) => {
                  if (prev) {
                    StopRecordingAnimations();
                    document.getElementsByClassName(
                      "recordbuttoncolor"
                    )[0].style.color = "blue";
                    setActiveDropdownName(view3D.animator.clips[0].name);
                  } else {
                    StartRecordingAnimations();
                    document.getElementsByClassName(
                      "recordbuttoncolor"
                    )[0].style.color = "red";
                  }
                  return !prev;
                })
              }
            >
              <BsFillRecord2Fill size={25} /> Record Animation
            </button>
          </div>
          <div className="">
            <div className={AnimationsCSS.recordedvideos}>
              Recorded Animations
            </div>
            <div className={AnimationsCSS.recordedvideosbutton}>
              <div className="dropdown">
                <button
                  className="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {activeDropdownName}
                </button>
                <ul className="dropdown-menu">
                  {view3D.animator.clips.map((animation, index) => (
                    <AnimationName
                      name={animation.name}
                      key={index}
                      onClick={() => {
                        view3D.animator.play(index);
                        setActiveDropdownName(animation.name);
                      }}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

const AnimationName = ({ name, onClick, index }) => {
  return (
    <>
      <li>
        <a className="dropdown-item" onClick={onClick}>
          {name}
        </a>
      </li>
    </>
  );
};

export default Animations;
