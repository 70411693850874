import React from "react";
import DisplayAndPanelCSS from "./DisplayAndPanel.module.css";
import Display from "./Display/Display";
import Panel from "./Panel/Panel";

function DisplayAndPanel({view3d}) {
  return (
    <div className={DisplayAndPanelCSS}>
      <Display />
      <Panel view3dInstance={view3d}/>
    </div>
  );
}

export default DisplayAndPanel;
