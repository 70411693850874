import React, { useState } from "react";
import { GetOptions } from "./utils/utils";
import {
  assembleAnimation,
  disassembleAnimation,
  HideAllAnnotations,
  ResetCamera,
  ShowAllAnnotations,
  Viewer,
} from "./Viewer/Viewer";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { isNumber } from "@obsidian/view3d";

let currentSelectedIndex = 0;
let annotationList;
let scene;
let camera;
let renderer;
let isAnimating = false;
let view3dInstance;

export const UsdzModel = ({ editMode = false, getView3D = (view3D) => { } }) => {
  // useState for Assemble/Disassemble
  const [isChanged, setIsChanged] = useState(true);
  // useState for prev and next
  const [isClicked, setIsClicked] = useState(false);
  const [currentAnnotationList, setAnnotation] = useState(undefined);
  const [currentAnnotationIndex, setAnnotationIndex] =
    useState(currentSelectedIndex);
  if (!editMode) {
    return (
      <div>
        <div>
          <div className="toolssection usdztoolssection mydiv">
            {/* Show Annotation Starts */}
            <button
              className="btn toolsimage"
              onClick={() => {
                setIsClicked((prevState) => !prevState);
                if (currentSelectedIndex != -1) {
                  GetOptions().selectCurrentAnnotation(
                    view3dInstance,
                    currentSelectedIndex,
                    annotationList
                  );
                } else {
                  GetOptions().selectCurrentAnnotation(
                    view3dInstance,
                    0,
                    annotationList
                  );
                }
                setAnnotation(annotationList);
              }}
            >
              <img src="/images/AnnotationIcon.svg" alt="Annotation" />
            </button>
          </div>
          {/* Previous and Next button */}
          {(currentAnnotationList && currentAnnotationList.length > 0) && (
            <NextPrevButton
              isClicked={isClicked}
              annotationList={currentAnnotationList}
              currentSelectedIndex={currentSelectedIndex}
            />
          )}

          {/* Where Loading Happens */}
          <div className="viewer-division">
            <Viewer
              src="models/PC.usdz"
              annotationURL="models/PC.json"
              isUsdz={true}
              editMode={editMode}
              getInstance={(view3d) => {
                view3dInstance = view3d;
                getView3D(view3d)
              }}
              setAnim={setAnim}
              setProperties={setProperties}
            />
          </div>
        </div>
      </div>
    );
  }
  else {
    return (<><div className="viewer-division">
      <Viewer
        src="els/PC.usdz"
        annotationURL="models/PC.json"
        isUsdz={true}
        editMode={editMode}
        getInstance={(view3d) => {
          view3dInstance = view3d;
          getView3D(view3d)
        }}
        setAnim={setAnim}
        setProperties={setProperties}
      />
    </div></>)
  }

};
const setProperties = (properties) => {
  annotationList = properties.annotationList;
  window.annotationList = annotationList;
  scene = properties.scene;
  camera = properties.camera;
  renderer = properties.renderer;
};
const setAnim = (anim) => {
  isAnimating = anim;
};
window.GetOptions = GetOptions;

// Annotations Caret Buttons
function NextPrevButton({ isClicked, annotationList }) {
  const [isAnnotated, setIsAnnotated] = useState(0);

  if (isClicked) {
    return (
      <div className="annotation-buttons-group">
        {/* Previous and Next button */}
        <button
          className="caret-left btn btn-outline-secondary"
          onClick={() => {
            if (isAnnotated > 0 && isAnnotated <= annotationList.length - 1) {
              setIsAnnotated(isAnnotated - 1);
            } else if (isAnnotated <= 0) {
              // isAnnotated = annotationList.length - 1;
              setIsAnnotated(isAnnotated + annotationList.length - 1);
            }
            currentSelectedIndex = GetOptions().prevAnnotation(
              view3dInstance,
              currentSelectedIndex,
              annotationList
            );
          }}
        >
          <FaChevronLeft size={30} />
        </button>

        {/* Annotation name updating  */}
        <div className="annotation-name">
          {annotationList.length > 0 && annotationList[isAnnotated].element.firstChild.getInnerHTML()}
        </div>

        <button
          className="caret-right btn btn-outline-secondary"
          onClick={() => {
            if (isAnnotated >= 0 && isAnnotated < annotationList.length - 1) {
              setIsAnnotated(isAnnotated + 1);
            } else if (isAnnotated >= annotationList.length - 1) {
              console.log("hello");
              setIsAnnotated(isAnnotated - (annotationList.length - 1));
            }
            currentSelectedIndex = GetOptions().nextAnnotation(
              view3dInstance,
              currentSelectedIndex,
              annotationList
            );
          }}
        >
          <FaChevronRight size={30} />
        </button>
      </div>
    );
  }
  else {
    return <></>;
  }
}

export default UsdzModel;
