// HomeCanvas Without frame code
import React, { useState } from "react";
import {
  Input,
  Button,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import { ICEngine } from "./IcEngine";
import { UsdzModel } from "./UsdzModel";
import ImportModel from "./ImportModel";
import { IoReorderThreeOutline } from "react-icons/io5";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { sleeper } from "./utils/utils";
import SideBar from "../components Editor/components/SideBar/SideBar";
import { MainSection } from "../components Editor/components/MainSection/MainSection";
import Panel from "../components Editor/components/MainSection/Display And Panel/Panel/Panel";
export const view3DContext = React.createContext(null);
export const isdUsdzModelContext = React.createContext(false);

export const HomeCanvas = (args) => {
  // accessing button for upload
  const offCanvasClose = () => {
    document.body.getElementsByClassName("btn-close")[0].click();
    console.log(offCanvasClose);
  };

  const [modal, setModal] = useState(false);
  const [isUsdzModel, setUsdzModel] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  // useState For Edit Mode
  const [editMode, setEditMode] = React.useState(false);

  // useState For Source and Usdz
  const [src, setSrc] = React.useState("");
  const [srcAnnotation, setAnnotation] = React.useState("");
  const [animationsArray, setAnimationsArray] = React.useState([]);
  const [isUsdz, setisUsdz] = React.useState(false);

  //useState for sidebar-button visibility
  const [sidebarVisible, setSidebarVisible] = React.useState(true);

  // useState For Viewers
  const [IcEngineActive, setIcEngineActive] = React.useState(true);
  const [UsdzModelActive, setUsdzModelActive] = React.useState(false);
  const [ImportModelActive, setImportModelActive] = React.useState(false);
  const [view3D, setView3D] = React.useState(null);

  function processFile(evt) {
    const file = evt.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async function (e) {
      const data = e.target.result;
      const blob = new Blob([data], { type: "application/octet-stream" });
      const src = URL.createObjectURL(blob);
      setSrc(src);
    };
  }

  function processAnnotationFile(evt) {
    const file = evt.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async function (e) {
      const data = e.target.result;
      const blob = new Blob([data], { type: "application/octet-stream" });
      const srcAnnotation = URL.createObjectURL(blob);
      setAnnotation(srcAnnotation);
    };
  }
  function processAnimationFile(evt) {
    const file = evt.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async function (event) {
      const data = JSON.parse(event.target.result);
      setAnimationsArray(data);
    };
  }

  return (
    <div>
      <div className="offcanvas offcanvas-start" id="demo">
        <div className="offcanvas-header">
          <h2 className="offcanvas-title">Upload Model</h2>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="offcanvas-body">
          <p>To View IC Engine</p>
          <Button
            color="primary"
            outline
            onClick={() => {
              setIcEngineActive(true);
              setUsdzModelActive(false);
              setImportModelActive(false);
            }}
          >
            IC Engine
          </Button>

          {/* USDZ  */}
          <p>To view Scanned USDZ model</p>
          <Button
            color="primary"
            outline
            onClick={() => {
              setIcEngineActive(false);
              setUsdzModelActive(true);
              setImportModelActive(false);
            }}
          >
            USDZ Model
          </Button>

          {/* Upload Model  */}
          <p>Import Model</p>
          <div>
            <Button
              color="primary"
              outline
              onClick={() => {
                toggle();
                setImportModelActive(false);
              }}
            >
              Import
            </Button>
            <Modal isOpen={modal} toggle={toggle} {...args}>
              <ModalHeader toggle={toggle}></ModalHeader>
              <ModalBody>
                Upload glb/usdz format model.
                <br />
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input myCheck"
                    type="checkbox"
                    checked={isUsdz}
                    id="flexCheckDefault"
                    onChange={() => {
                      setisUsdz((prevState) => !prevState);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Is usdz
                  </label>
                </div>
              </ModalBody>
              <ModalFooter>
                <div>Import Model</div>
                <Input
                  id="modelFile"
                  name="fields[assetsFieldHandle][]"
                  type="file"
                  multiple
                  onChange={(evt) => {
                    processFile(evt);
                  }}
                />
                <div>Import Annotations</div>
                <Input
                  id="annotationFile"
                  name=""
                  type="file"
                  multiple
                  onChange={(evt) => {
                    processAnnotationFile(evt);
                  }}
                />
                <div>Import Animations</div>
                <Input
                  id="animationFile"
                  name=""
                  type="file"
                  multiple
                  onChange={(evt) => {
                    processAnimationFile(evt);
                  }}
                />
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    // toggle for modal and offcanvas
                    if (src === "") return;
                    setIcEngineActive(false);
                    setUsdzModelActive(false);
                    setImportModelActive(true);
                    toggle();
                    offCanvasClose();
                  }}
                >
                  OK
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
      <button
        className="btn btn-outline-info sidebar-button"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#demo"
      >
        <IoReorderThreeOutline size={30} />
      </button>
      <view3DContext.Provider value={view3D}>
        <isdUsdzModelContext.Provider value={isUsdzModel}>
          {/* Button For Edit Panel */}
          {editMode ? (
            <div>
              <Panel view3dInstance={view3D} />
              <button
                className="btn btn-outline-info back-button"
                style={{
                  width: "5%",
                  height: "5%",
                  position: "fixed",
                  left: "2.5%",
                  top: "6.5%",
                }}
                onClick={() => {
                  if (sidebarVisible) {
                    document
                      .querySelector(".back-button")
                      .classList.add("hidden");
                  } else {
                    document
                      .querySelector(".sidebar-button")
                      .classList.remove("hidden");
                  }
                  setSidebarVisible((prevState) => !prevState);

                  // toggle for modal and offcanvas
                  const modelActive = {
                    IcEngineActive: IcEngineActive,
                    UsdzModelActive: UsdzModelActive,
                    ImportModelActive: ImportModelActive,
                  };
                  setIcEngineActive(false);
                  setUsdzModelActive(false);
                  setImportModelActive(false);
                  setEditMode((prevState) => !prevState);

                  setTimeout(() => {
                    setIcEngineActive(modelActive.IcEngineActive);
                    setUsdzModelActive(modelActive.UsdzModelActive);
                    setImportModelActive(modelActive.ImportModelActive);
                  }, 100);
                }}
              >
                Back
              </button>
            </div>
          ) : (
            <div className="edit-button">
              <button
                className="btn btn-lg btn-outline-info edit-button-button"
                onClick={() => {
                  if (sidebarVisible) {
                    document
                      .querySelector(".sidebar-button")
                      .classList.add("hidden");
                  } else {
                    document
                      .querySelector(".sidebar-button")
                      .classList.remove("hidden");
                  }
                  setSidebarVisible((prevState) => !prevState);

                  // toggle for modal and offcanvas
                  const modelActive = {
                    IcEngineActive: IcEngineActive,
                    UsdzModelActive: UsdzModelActive,
                    ImportModelActive: ImportModelActive,
                  };
                  setIcEngineActive(false);
                  setUsdzModelActive(false);
                  setImportModelActive(false);
                  setEditMode((prevState) => !prevState);

                  setTimeout(() => {
                    setIcEngineActive(modelActive.IcEngineActive);
                    setUsdzModelActive(modelActive.UsdzModelActive);
                    setImportModelActive(modelActive.ImportModelActive);
                  }, 100);
                }}
              >
                Edit
              </button>
            </div>
          )}
          {IcEngineActive ? (
            <ICEngine
              editMode={editMode}
              getView3D={(view3D) => {
                setView3D(view3D);
                setUsdzModel(false);
              }}
            />
          ) : (
            <></>
          )}
          {UsdzModelActive ? (
            <UsdzModel
              editMode={editMode}
              getView3D={(view3D) => {
                setView3D(view3D);
                setUsdzModel(true);
              }}
            />
          ) : (
            <></>
          )}
          {ImportModelActive ? (
            <ImportModel
              src={src}
              isUsdz={isUsdz}
              annotationURL={srcAnnotation}
              editmode={editMode}
              getView3D={(view3D) => {
                setView3D(view3D);
                setUsdzModel(isUsdz);
              }}
              animations={animationsArray}
            />
          ) : (
            <></>
          )}
        </isdUsdzModelContext.Provider>
      </view3DContext.Provider>
    </div>
  );
  {
  }
};
