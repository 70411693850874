import { IoReorderThreeOutline } from "react-icons/io5";
import React from "react";
import { Button } from "reactstrap";
import { useEffect } from "react";
import { MarkersPlugin } from "@photo-sphere-viewer/markers-plugin";
import { Viewer, EquirectangularAdapter } from "@photo-sphere-viewer/core";
import axios from "axios";
window.axios = axios;
// const response = await fetch("output/images 3 (copy)_1715172541.0508142.jpg")
//           const blob = await response.blob()
//           const url = URL.createObjectURL(blob);

const APICallForLinks = async () => {
  const getUrl = `https://mediaeditor.7seers.ai/pano`;
  console.log("The URLs are here");
  const getResponse = await axios.get(getUrl);

  console.log(getResponse.data);
  return getResponse.data; // Assuming the data you need is in the response data property
};

const PanoViewer = (url) => {
  console.log(`url:-->${url}`);
  const viewer = new Viewer({
    container: document.querySelector("#panoramaviewer"),
    panorama: url,
    plugins: [[MarkersPlugin]],
    adapter: [
      EquirectangularAdapter,
      {
        interpolateBackground: true,
      },
    ],
    mousewheel: false,
  });
  const extendedPosition = { yaw: 0, pitch: 0 };
  const addRotation = (scrollValue) => {
    extendedPosition.yaw += scrollValue;
    viewer.rotate(extendedPosition);
  };
  window.viewer = viewer;
  window.addRotation = addRotation;
  const markersPlugin = viewer.getPlugin(MarkersPlugin);
  viewer.addEventListener("dblclick", ({ data }) => {
    viewer.setOptions({
      mousemove: false,
    });
    const markerId = "#" + Math.random();
    const submitButton = document.createElement("button");
    submitButton.classList.add("checkbutton");
    submitButton.classList.add("btn");
    submitButton.style.position = "absolute";
    submitButton.style.top = data.clientY + 10 + "px";
    submitButton.style.left = data.clientX + 265 + "px";
    submitButton.addEventListener("click", () => {
      console.log("changed:" + input.value);
      if (input.value === "") {
        return;
      }
      submitButton.classList.add("hidden");
      removeButton.classList.add("hidden");
      input.classList.add("hidden");
      markersPlugin.updateMarker({
        id: markerId,
        tooltip: input.value,
      });
      viewer.setOptions({
        mousemove: true,
      });
    });
    // Remove Button
    const removeButton = document.createElement("button");
    removeButton.classList.add("crossbutton");
    removeButton.classList.add("btn");
    removeButton.style.position = "absolute";
    removeButton.style.top = data.clientY + 10 + "px";
    removeButton.style.left = data.clientX + 295 + "px";
    removeButton.addEventListener("click", () => {
      removeButton.classList.add("hidden");
      submitButton.classList.add("hidden");
      markersPlugin.removeMarker(markerId);
      input.classList.add("hidden");
      viewer.setOptions({
        mousemove: true,
      });
      console.log("changed:" + input.value);
    });

    const input = document.createElement("input");
    input.style.position = "absolute";
    input.style.top = data.clientY + 10 + "px";
    input.style.left = data.clientX + 10 + "px";
    console.log(data);
    input.addEventListener("change", () => {
      console.log("changed:" + input.value);
      markersPlugin.updateMarker({
        id: markerId,
        tooltip: input.value,
      });
      viewer.setOptions({
        mousemove: true,
      });
    });

    document.getElementById("panoramaviewer").appendChild(input);    
    document.getElementById("panoramaviewer").appendChild(submitButton);

    document.getElementById("panoramaviewer").appendChild(removeButton);
    markersPlugin.addMarker({
      id: markerId,
      // imageLayer: baseUrl + 'pictos/tent.png',
      imageLayer: "images/pin-red.png",
      size: { width: 32, height: 32 },
      position: { yaw: data.yaw, pitch: data.pitch },
      tooltip: "",
    });
    window.markersPlugin = markersPlugin;
    console.log(markersPlugin);
  });

  const exportBtn = document.createElement("button");
  exportBtn.setAttribute("position", "fixed");
  exportBtn.innerHTML = "Export Annotations";
  exportBtn.classList.add("btn");
  exportBtn.classList.add("btn-outline-light");
  exportBtn.addEventListener("click", () => {
    if (markersPlugin === undefined) {
      return;
    }
    let annotationData = [];
    for (let marker of markersPlugin.getMarkers()) {
      console.log(marker.config.position);
      console.log(marker.config.tooltip);
      annotationData.push({
        id: marker.config.id,
        position: marker.config.position,
        tooltip: marker.config.tooltip,
      });
    }
    if (annotationData.length === 0) {
      return;
    }
    const dataBlob = new Blob([JSON.stringify(annotationData)], {
      type: "application/json",
    });
    const link = document.createElement("a");
    link.style.display = "none";
    document.getElementById("panoramaviewer").appendChild(link);
    link.href = URL.createObjectURL(dataBlob);
    link.download = "annotation.json";
    link.click();
    document.body.removeChild(link);
  });
  exportBtn.style.position = "absolute";
  exportBtn.style.top = "10px";
  exportBtn.style.left = "10px";
  document.getElementById("panoramaviewer").appendChild(exportBtn);

  // Import Annotations Button
  const importAnnotationBtn = document.createElement("input");
  importAnnotationBtn.setAttribute("position", "fixed");
  importAnnotationBtn.innerHTML = "import";
  importAnnotationBtn.type = "file";
  importAnnotationBtn.accept = ".json";
  importAnnotationBtn.placeholder = "Import Annotations";
  importAnnotationBtn.classList.add("form-control");
  importAnnotationBtn.classList.add("importannotationbutton");
  importAnnotationBtn.addEventListener("change", (event) => {
    if (event.target.files != null && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = JSON.parse(event.target.result);
        if (Object.keys(data).length === 0) {
          return;
        }
        data.forEach((element) => {
          markersPlugin.addMarker({
            id: element.id,
            // imageLayer: baseUrl + 'pictos/tent.png',
            imageLayer: "images/pin-red.png",
            size: { width: 32, height: 32 },
            position: element.position,
            tooltip: element.tooltip,
          });
        });
      };

      reader.readAsText(file);
    }
  });
  importAnnotationBtn.style.position = "absolute";
  //   importAnnotationBtn.style.top = "10px";
  //   importAnnotationBtn.style.left = "100px";
  document.getElementById("panoramaviewer").appendChild(importAnnotationBtn);
};

window.PanoViewer = PanoViewer;

export const StitchesViewer = () => {
  // let panoramaUrls = ["http://localhost:3000/panoramaPrototype1.jpg"];
  const [panoramaUrls, setPanoramaUrls] = React.useState([]);
  //api call -->[urls] --> panoramaUrls
  // APICallForLinks();
  useEffect(() => {
    console.log("reached in the function");
    APICallForLinks().then((urls) => {
      console.log(urls);
      setPanoramaUrls(urls);
    });
  }, []);
  console.log("reached out of the function");

  const NameGenerator = (element) => {
    const arr = element.split("output")[1].split("/");
    const name = `${arr[arr.length - 1].split("_")[0]}.jpg`;
    return name;
  };
  const replaceSpacesWithURL = (url) => {
    return url.replace(/ /g, "%20");
  };

  return (
    <>
      <a
        className="btn-outline-info sidebar-button"
        data-bs-toggle="offcanvas"
        href="#offcanvasExample"
        role="button"
        aria-controls="offcanvasExample"
      >
        <IoReorderThreeOutline size={30} id="something-button" />
      </a>

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h4 className="offcanvas-title" id="offcanvasExampleLabel">
            Panaroma Links
          </h4>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div style={{ overflowY: "scroll" }} className="offcanvas-body">
          <ul style={{ listStyle: "none" }}>
            {panoramaUrls.length > 0 &&
              panoramaUrls.map((element) => (
                <>
                  <li>
                    <button
                      className="btn btn-outline-info"
                      onClick={() => PanoViewer(replaceSpacesWithURL(element))}
                    >
                      {NameGenerator(element)}
                    </button>
                  </li>
                </>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};
