import "@photo-sphere-viewer/core/index.css";
import { Viewer, EquirectangularAdapter } from "@photo-sphere-viewer/core";
import { useEffect } from "react";
import { MarkersPlugin } from "@photo-sphere-viewer/markers-plugin";
import { StitchesViewer } from "../StitchesViewer/StitchesViewer";

export const PanaromaViewer = () => {
  useEffect(() => {
    const importPanoBtn = document.createElement("input");
    importPanoBtn.classList.add("form-control");
    importPanoBtn.classList.add("importpanobutton");
    importPanoBtn.innerHTML = "import";
    importPanoBtn.setAttribute("id", "file-upload");
    importPanoBtn.type = "file";
    importPanoBtn.style.display = "none";
    importPanoBtn.accept = ".png,.jpg,.jpeg";
    importPanoBtn.placeholder = "Import Annotations";
    importPanoBtn.addEventListener("change", (event) => {
      if (event.target.files != null && event.target.files.length > 0) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target.result;
          const blob = new Blob([data], {
            type: "application/octet-stream",
          });
          const url = URL.createObjectURL(blob);
          const viewer = new Viewer({
            container: document.querySelector("#panoramaviewer"),
            panorama: url,
            plugins: [[MarkersPlugin]],
            adapter: [
              EquirectangularAdapter,
              {
                interpolateBackground: true,
              },
            ],
            mousewheel: false,
          });
          const extendedPosition = { yaw: 0, pitch: 0 };
          const addRotation = (scrollValue) => {
            extendedPosition.yaw += scrollValue;
            viewer.rotate(extendedPosition);
          };
          window.viewer = viewer;
          window.addRotation = addRotation;
          const markersPlugin = viewer.getPlugin(MarkersPlugin);
          viewer.addEventListener("dblclick", ({ data }) => {
            viewer.setOptions({
              mousemove: false,
            });
            const markerId = "#" + Math.random();

            const submitButton = document.createElement("button");
            submitButton.classList.add("checkbutton");
            submitButton.classList.add("btn");
            submitButton.style.position = "absolute";
            submitButton.style.top = data.clientY + 10 + "px";
            submitButton.style.left = data.clientX + 265 + "px";
            submitButton.addEventListener("click", () => {
              console.log("changed:" + input.value);
              if (input.value === "") {
                return;
              }
              submitButton.classList.add("hidden");
              removeButton.classList.add("hidden");
              input.classList.add("hidden");
              markersPlugin.updateMarker({
                id: markerId,
                tooltip: input.value,
              });
            });

            // Remove Button
            const removeButton = document.createElement("button");
            removeButton.classList.add("crossbutton");
            removeButton.classList.add("btn");
            removeButton.style.position = "absolute";
            removeButton.style.top = data.clientY + 10 + "px";
            removeButton.style.left = data.clientX + 295 + "px";
            removeButton.addEventListener("click", () => {
              removeButton.classList.add("hidden");
              submitButton.classList.add("hidden");
              markersPlugin.removeMarker(markerId);
              input.classList.add("hidden");

              console.log("changed:" + input.value);
            });

            const input = document.createElement("input");
            input.style.position = "absolute";
            input.style.top = data.clientY + 10 + "px";
            input.style.left = data.clientX + 10 + "px";
            console.log(data);
            input.addEventListener("change", () => {
              console.log("changed:" + input.value);
              markersPlugin.updateMarker({
                id: markerId,
                tooltip: input.value,
              });
              viewer.setOptions({
                mousemove: true,
              });
            });

            document.getElementById("panoramaviewer").appendChild(input);
            document.getElementById("panoramaviewer").appendChild(submitButton);
            document.getElementById("panoramaviewer").appendChild(removeButton);
            markersPlugin.addMarker({
              id: markerId,
              // imageLayer: baseUrl + 'pictos/tent.png',
              imageLayer: "images/pin-red.png",
              size: { width: 32, height: 32 },
              position: { yaw: data.yaw, pitch: data.pitch },
              tooltip: "",
            });
            window.markersPlugin = markersPlugin;
            console.log(markersPlugin);
          });

          const exportBtn = document.createElement("button");
          exportBtn.setAttribute("position", "fixed");
          // exportBtn.setAttribute("top", "3px");
          exportBtn.innerHTML = "Export Annotations";
          exportBtn.classList.add("btn");
          exportBtn.classList.add("btn-outline-light");
          exportBtn.addEventListener("click", () => {
            if (markersPlugin === undefined) {
              return;
            }
            let annotationData = [];
            for (let marker of markersPlugin.getMarkers()) {
              console.log(marker.config.position);
              console.log(marker.config.tooltip);
              annotationData.push({
                id: marker.config.id,
                position: marker.config.position,
                tooltip: marker.config.tooltip,
              });
            }
            if (annotationData.length === 0) {
              return;
            }
            const dataBlob = new Blob([JSON.stringify(annotationData)], {
              type: "application/json",
            });
            const link = document.createElement("a");
            link.style.display = "none";
            document.getElementById("panoramaviewer").appendChild(link);
            link.href = URL.createObjectURL(dataBlob);
            link.download = "annotation.json";
            link.click();
            document.body.removeChild(link);
          });
          exportBtn.style.position = "absolute";
          exportBtn.style.top = "10px";
          exportBtn.style.left = "10px";
          document.getElementById("panoramaviewer").appendChild(exportBtn);

          // Import Annotations Button
          const importAnnotationBtn = document.createElement("input");
          importAnnotationBtn.setAttribute("position", "fixed");
          importAnnotationBtn.innerHTML = "import";
          importAnnotationBtn.type = "file";
          importAnnotationBtn.accept = ".json";
          importAnnotationBtn.placeholder = "Import Annotations";
          importAnnotationBtn.classList.add("form-control");
          importAnnotationBtn.classList.add("importannotationbutton");
          importAnnotationBtn.addEventListener("change", (event) => {
            if (event.target.files != null && event.target.files.length > 0) {
              const file = event.target.files[0];
              const reader = new FileReader();
              reader.onload = (event) => {
                const data = JSON.parse(event.target.result);
                if (Object.keys(data).length === 0) {
                  return;
                }
                data.forEach((element) => {
                  markersPlugin.addMarker({
                    id: element.id,
                    // imageLayer: baseUrl + 'pictos/tent.png',
                    imageLayer: "images/pin-red.png",
                    size: { width: 32, height: 32 },
                    position: element.position,
                    tooltip: element.tooltip,
                  });
                });
              };

              reader.readAsText(file);
            }
            importPanoLabel.remove();
          });
          importAnnotationBtn.style.position = "absolute";
          //   importAnnotationBtn.style.top = "10px";
          //   importAnnotationBtn.style.left = "100px";
          document
            .getElementById("panoramaviewer")
            .appendChild(importAnnotationBtn);
          importPanoLabel.classList.add("hidden");
        };
        reader.readAsArrayBuffer(file);
      }
    });

    const importPanoLabel = document.createElement("label");
    importPanoLabel.setAttribute("for", "file-upload");
    importPanoLabel.setAttribute("class", "custom-file-upload");
    importPanoLabel.textContent = "Click to add Panorama Here";
    importPanoLabel.style.position = "absolute";
    // importPanoLabel.style.top = "100px";
    // importPanoLabel.style.left = "10px";
    importPanoLabel.classList.add("btn");
    importPanoLabel.classList.add("btn-outline-secondary");
    importPanoLabel.classList.add("importpanolabel");
    importPanoLabel.classList.add("btn-lg");

    importPanoBtn.style.position = "absolute";
    // importPanoBtn.style.top = "100px";
    // importPanoBtn.style.left = "10px";
    document.getElementById("panoramaviewer").appendChild(importPanoBtn);
    document.getElementById("panoramaviewer").appendChild(importPanoLabel);

    importPanoLabel.addEventListener("click", function () {});
  });

  return (
    <div id="panoramaviewer" style={{ width: "100vw", height: "100vh" }}>
      <StitchesViewer />
    </div>
  );
};
