import { LoadingBar, View3dPlayer } from "@obsidian/view3d";
import {
  animate,
  PostProcessingOutline,
  setMesh,
  setRender,
} from "./PostProcessing";

export const Viewer = ({
  src,
  annotationURL,
  isUsdz,
  editMode = false,
  pitch = 0,
  yaw = 30,
  getInstance = (view3d) => { },
  setAnim = (anim) => { },
  setProperties = (properties) => { },
}) => {
  const handleOnLoad = (view3d) => {
    getInstance(view3d);
    let properties = Init(view3d);
    setProperties(properties);
    OnAnimationFinished(view3d, setAnim);
  };
  const handleOnCreate = (view3d) => {
    console.log("onCreate:");
    const loadingBar = new LoadingBar({
      type: "spinner",
      loadingLabel: "Loading...",
      labelColor: "rgba(0,0,0,0.67)",
      overlayBackground: "rgba(0,0,0,0)",
      barForeground: "rgba(188,38,75,0.67)",
    });
    // view3DRef.current.loadPlugins(new ARButton(), loadingBar);
    view3d.loadPlugins(loadingBar);
  };
  return (
    <View3dPlayer
      src={src}
      usdzSrc={isUsdz ? src : ""}
      pitch={pitch}
      yaw={yaw}
      envmap={"studio_small_08_1k.hdr"}
      annotationURL={annotationURL}
      editMode={editMode}
      onLoad={handleOnLoad}
      onCreate={handleOnCreate}
    />
  );
};
const Init = (view3dRef) => {
  let annotationList = view3dRef.annotation.list;
  let scene = view3dRef.scene.root;
  let camera = view3dRef.camera.threeCamera;
  let renderer = view3dRef.renderer.threeRenderer;
  for (let index = 0; index < annotationList.length; index++) {
    view3dRef.annotation.list[index].element.style.width = "30px";
    view3dRef.annotation.list[index].element.style.height = "30px";
    view3dRef.annotation.list[index].element.style.fontSize = "25px";
    view3dRef.annotation.list[index].element.style.color = "rgb(192, 192, 192)";
    view3dRef.annotation.list[index].element.style.backgroundColor =
      "rgb(188, 38, 75)";
    view3dRef.annotation.list[index].element.addEventListener("click", () => {
      MakeAnnotationHide(index, view3dRef);
      // SelectObjectToHighlight(scene, camera, renderer, index, view3dRef);
    });
  }
  HideAllAnnotations(view3dRef);
  return {
    annotationList: annotationList,
    scene: scene,
    camera: camera,
    renderer: renderer,
  };
  // animate()
};
export function SelectObjectToHighlight(
  scene,
  camera,
  renderer,
  index,
  view3dRef
) {
  let obj = view3dRef.model.meshes[view3dRef.annotation.list[index].meshIndex];
  setMesh([obj]);
  PostProcessingOutline(scene, camera, renderer);
  setRender(true);
}
export function MakeAnnotationHide(showIndex, view3dRef, currentSelectedIndex) {
  currentSelectedIndex = showIndex;
  let annotationList = view3dRef.annotation.list;
  for (let index = 0; index < annotationList.length; index++) {
    if (index !== showIndex) {
      view3dRef.annotation.list[index].element.style.visibility = "hidden";
    } else {
      view3dRef.annotation.list[index].element.style.visibility = "visible";
    }
  }
}
export function ShowAllAnnotations(view3dRef) {
  let annotationList = view3dRef.annotation.list;

  for (let index = 0; index < annotationList.length; index++) {
    view3dRef.annotation.list[index].element.style.visibility = "visible";
  }
}
export function HideAllAnnotations(view3dRef) {
  let annotationList = view3dRef.annotation.list;

  for (let index = 0; index < annotationList.length; index++) {
    view3dRef.annotation.list[index].element.style.visibility = "hidden";
    view3dRef.annotation.list[index].unfocus();
  }
}
export const disassembleAnimation = (
  view3dRef,
  setAnim = (anim) => { },
  anim
) => {
  if (
    (view3dRef.animator.activeAnimation.name === "3.Assemble" ||
      view3dRef.animator.activeAnimation.name === "1.Idle" ||
      view3dRef.animator.activeAnimation.name === "4.Idle(def)" || view3dRef.animator.activeAnimation.name === "Assemble" || view3dRef.animator.activeAnimation.name === "Idle") &&
    anim === false
  ) {
    view3dRef.animator.play(1);
    setAnim(true);
    setRender(true);
  }
};

export const assembleAnimation = (view3dRef, setAnim = (anim) => { }, anim) => {
  if (
    (view3dRef.animator.activeAnimation.name === "2.DissAssemble" || view3dRef.animator.activeAnimation.name === "DisAssemble") &&
    anim === false
  ) {
    view3dRef.animator.play(2);
    setAnim(true);
    setMesh(null);
    setRender(false);
  }
};
/**
 * Reset Camera Position According to Default Pose
 * @param {*} hideAnnotations Hide Annotations Wnen this is true {default:true}
 * @param {*} duration Duration Taken By Camera to Reset the Position
 */
export const ResetCamera = (
  view3dRef,
  setAnim = (anim) => { },
  anim,
  callAssemble = true,
  duration = 1200
) => {
  setMesh(null);
  setRender(false);
  if (callAssemble) {
    assembleAnimation(view3dRef, setAnim, anim);
    HideAllAnnotations(view3dRef);
  }
  view3dRef.camera.reset(duration);
};
export function SetDefaultPose(
  action,
  view3dRef,
  idlePose = {
    pitch: 3.9375,
    yaw: 24.25,
    pivot: {
      x: 1.6890270934655227e-7,
      y: 0.24767144889937961,
      z: 0.06445496684113944,
    },
    zoom: 16.56,
  },
  disassemblePose = {
    pitch: 20.25,
    yaw: 22.25,
    pivot: {
      x: 1.6890270934655227e-7,
      y: 0.24767144889937961,
      z: 0.06445496684113944,
    },
    zoom: -24.0,
  }
) {
  if (
    action === "3.Assemble" || action === "Assemble" ||
    action === "1.Idle" || action === "Idle" ||
    action === "4.Idle(def)"
  ) {
    view3dRef.camera.defaultPose.pitch = idlePose.pitch;
    view3dRef.camera.defaultPose.yaw = idlePose.yaw;
    view3dRef.camera.defaultPose.pivot.x = idlePose.pivot.x;
    view3dRef.camera.defaultPose.pivot.y = idlePose.pivot.y;
    view3dRef.camera.defaultPose.pivot.z = idlePose.pivot.z;
    view3dRef.camera.defaultPose.zoom = idlePose.zoom;
  }
  if (action === "2.DissAssemble" || action === "DisAssemble") {
    view3dRef.camera.defaultPose.pitch = disassemblePose.pitch;
    view3dRef.camera.defaultPose.yaw = disassemblePose.yaw;
    view3dRef.camera.defaultPose.pivot.x = disassemblePose.pivot.x;
    view3dRef.camera.defaultPose.pivot.y = disassemblePose.pivot.y;
    view3dRef.camera.defaultPose.pivot.z = disassemblePose.pivot.z;
    view3dRef.camera.defaultPose.zoom = disassemblePose.zoom;
  }
}
function OnAnimationFinished(view3dRef, setAnim = (anim) => { }) {
  view3dRef.on("animationFinished", (evt) => {
    console.log(evt);
    if (evt.index === -1) {
      return;
    }
    let action = evt?.clip?.name;
    console.log(action);
    if (
      evt.clip.name === "3.Assemble" || evt.clip.name === "Assemble" ||
      evt.clip.name === "1.Idle" || evt.clip.name === "Idle" ||
      evt.clip.name === "4.Idle(def)"
    ) {
      HideAllAnnotations(view3dRef);
      SetDefaultPose("3.Assemble", view3dRef);
      ResetCamera(view3dRef, setAnim, false, false);
    }
    if (action === "2.DissAssemble" || action === "DisAssemble") {
      ShowAllAnnotations(view3dRef);
      SetDefaultPose("2.DissAssemble", view3dRef);
      ResetCamera(view3dRef, setAnim, false, false);
    }
    setAnim(false);
  });
  view3dRef.on("render", (evt) => {
    animate();
  });
}
