import React, { useContext } from "react";
import PanelCSS from "./Panel.module.css";
// import Environment from "./Environment/Environment";
// import Background from "./Background/Background";
// import Shadow from "./Shadows/Shadow";
import Animations from "./Animations/Animations";
import Annotation from "./Annotation/Annotation";
import { isdUsdzModelContext } from "../../../../../components/HomeCanvas";

function Panel({view3dInstance}) {
  const isUsdz = useContext(isdUsdzModelContext)
  return (
    <div className={PanelCSS.panel}>
      {/* <Environment /> */}
      {/* <Background /> */}
      {/* <Shadow /> */}
      {!isUsdz && <Animations />}
      <Annotation view3dInstance={view3dInstance}/>
    </div>
  );
}

export default Panel;
