import React from "react";
import "./Panel.css";
import { HomeCanvas } from "../HomeCanvas";
import { PanaromaViewer } from "../PanoramaViewer/PanoramaViewer";
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import { PiCubeFocus } from "react-icons/pi";
import { LuRotate3D } from "react-icons/lu";
import { StitchesViewer } from "../StitchesViewer/StitchesViewer";

function Panel() {
  // useState For Panorama
  const [isHome, setIsHome] = React.useState(false);
  const [isLabel, setIsLabel] = React.useState("Home");

  return (
    <div>
      <BrowserRouter basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <div className="entrypanel">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <NavLink
                    className="btn button-secondary navlinkoutline entrypanelpanorama3D"
                    to="/panorama"
                    onClick={() => {
                      setIsLabel("Pano");
                    }}
                  >
                    <LuRotate3D size={35} />
                    Panorama
                  </NavLink>
                </div>
                <img
                  src="/images/47billionlogo.svg"
                  alt="47billion logo"
                  style={{ marginBottom: "65vh" }}
                />
                <div className="entrypaneltext ">
                  Experience Panaroma View Or 3D
                </div>
                <NavLink
                  className="btn button-secondary navlinkoutline2 entrypanelpanorama3D"
                  to="/3D"
                  onClick={() => {
                    setIsLabel("3D");
                  }}
                >
                  <PiCubeFocus size={35} />
                  3D
                </NavLink>
              </div>
            }
          ></Route>
          <Route
            path="/3D"
            element={
              <div className="viewer">
                <div className="canvas">
                  <HomeCanvas />
                </div>
              </div>
            }
          />
          <Route
            path="panorama"
            element={
              <div>
                {/* <NavLink
                  className="btn button-secondary-sm navlinkoutline entrypanelpanorama3D"
                  to="/"
                  style={{
                    position: "absolute",
                    zIndex: "10",
                  }}
                  onClick={() => {
                    if (isHome) {
                      <PanaromaViewer />;
                    }
                    setIsHome((prevState) => !prevState);
                  }}
                >
                  Home
                </NavLink> */}
                <PanaromaViewer />
              </div>
            }
          />
          <Route path="panorama/stitches" element={<StitchesViewer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Panel;
