import React, { createContext, useState } from "react";
import { GetOptions } from "./utils/utils";
import {
  assembleAnimation,
  disassembleAnimation,
  HideAllAnnotations,
  ResetCamera,
  ShowAllAnnotations,
  Viewer,
} from "./Viewer/Viewer";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { isNumber } from "@obsidian/view3d";

let currentSelectedIndex = 0;
let annotationList;
let scene;
let camera;
let renderer;
let isAnimating = false;
let view3dInstance;

export const ICEngine = ({ editMode = false, getView3D = (view3D) => {} }) => {
  return (
    <>
      <div>
        <ToolsPanelForIcEngine editMode={editMode} />

        <div className="viewer-division">
          <Viewer
            src="models/V_Shape_IC_ENGINE.glb"
            annotationURL="models/V_Shape_IC_ENGINE.json"
            isUsdz={false}
            editMode={editMode}
            getInstance={(view3d) => {
              view3dInstance = view3d;
              window.view3d = view3d;
              getView3D(view3d);
            }}
            setAnim={setAnim}
            setProperties={setProperties}
          />
        </div>
      </div>
    </>
  );
};
const setProperties = (properties) => {
  annotationList = properties.annotationList;
  window.annotationList = annotationList;
  scene = properties.scene;
  camera = properties.camera;
  renderer = properties.renderer;
};
const setAnim = (anim) => {
  isAnimating = anim;
};
window.GetOptions = GetOptions;

// Annotations Caret Buttons
function NextPrevButton({ isClicked, annotationList }) {
  const [isAnnotated, setIsAnnotated] = useState(0);

  if (isClicked) {
    return (
      <div className="annotation-buttons-group">
        {/* Previous and Next button */}
        <button
          className="caret-left btn btn-outline-secondary"
          onClick={() => {
            if (isAnnotated > 0 && isAnnotated <= annotationList.length - 1) {
              setIsAnnotated(isAnnotated - 1);
            } else if (isAnnotated <= 0) {
              // isAnnotated = annotationList.length - 1;
              setIsAnnotated(isAnnotated + annotationList.length - 1);
            }
            currentSelectedIndex = GetOptions().prevAnnotation(
              view3dInstance,
              currentSelectedIndex,
              annotationList
            );
          }}
        >
          <FaChevronLeft size={30} />
        </button>

        {/* Annotation name updating  */}
        <div className="annotation-name">
          {annotationList.length >0 &&annotationList[isAnnotated].element.firstChild.getInnerHTML()}
        </div>

        <button
          className="caret-right btn btn-outline-secondary"
          onClick={() => {
            if (isAnnotated >= 0 && isAnnotated < annotationList.length - 1) {
              setIsAnnotated(isAnnotated + 1);
            } else if (isAnnotated >= annotationList.length - 1) {
              console.log("hello");
              setIsAnnotated(isAnnotated - (annotationList.length - 1));
            }
            currentSelectedIndex = GetOptions().nextAnnotation(
              view3dInstance,
              currentSelectedIndex,
              annotationList
            );
          }}
        >
          <FaChevronRight size={30} />
        </button>
      </div>
    );
  } else {
    return <></>;
  }
}

export const ToolsPanelForIcEngine = ({ editMode }) => {
  // console.log(
  //   document.getElementsByClassName("sidebar-button")[0].classList.add("hidden")
  // );
  // if (editMode) {
  //   // console.log(document.getElementsByClassName("sidebar-button")[0].classList);
  //   document
  //     .getElementsByClassName("sidebar-button")[0]
  //     .classList.add("hidden");
  // } else {
  //   document
  //     .getElementsByClassName("sidebar-button")[0]
  //     .classList.remove("hidden");
  // }
  // useState for Assemble/Disassemble
  const [isChanged, setIsChanged] = useState(true);
  // useState for prev and next
  const [isClicked, setIsClicked] = useState(false);
  const [currentAnnotationList, setAnnotation] = useState(undefined);
  const [currentAnnotationIndex, setAnnotationIndex] =
    useState(currentSelectedIndex);

  if (!editMode) {
    return (
      <>
        <div className="toolssection mydiv">
          {/* Assemble/Disassemble button */}
          <button
            className="btn toolsimage"
            onClick={() => {
              console.log(view3dInstance);
              if (isAnimating) return;
              setIsChanged((prevState) => !prevState);
              if (!isChanged) {
                GetOptions().asssemble(view3dInstance, setAnim, isAnimating);
              } else {
                GetOptions().disassemble(view3dInstance, setAnim, isAnimating);
              }
            }}
          >
            <img src="/images/AssembleIcon.svg" alt="Annotation" />
          </button>

          {/* Reset button starts */}
          <button
            className="btn toolsimage"
            onClick={() => {
              GetOptions().resetCamera(view3dInstance, setAnim, isAnimating);
            }}
          >
            <img src="/images/ResetIcon.svg" alt="Annotation" />
          </button>

          {/* Show Annotation Starts */}
          <button
            className="btn toolsimage"
            onClick={() => {
              setIsClicked((prevState) => !prevState);
              if (currentSelectedIndex != -1) {
                GetOptions().selectCurrentAnnotation(
                  view3dInstance,
                  currentSelectedIndex,
                  annotationList
                );
              } else {
                GetOptions().selectCurrentAnnotation(
                  view3dInstance,
                  0,
                  annotationList
                );
              }
              setAnnotation(annotationList);
            }}
          >
            <img src="/images/AnnotationIcon.svg" alt="Annotation" />
          </button>
        </div>

        {/* Previous and Next button */}
        {(currentAnnotationList && currentAnnotationList.length > 0) && (
          <NextPrevButton
            isClicked={isClicked}
            annotationList={currentAnnotationList}
            currentSelectedIndex={currentSelectedIndex}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default ICEngine;
