import {
  assembleAnimation,
  disassembleAnimation,
  ResetCamera,
  ShowAllAnnotations,
} from "../Viewer/Viewer";

export const GetOptions = () => {
  return {
    asssemble: (view3dInstance, setAnim, isAnimating) => {
      if (view3dInstance) {
        assembleAnimation(view3dInstance, setAnim, isAnimating);
      }
    },
    disassemble: (view3dInstance, setAnim, isAnimating) => {
      if (view3dInstance) {
        disassembleAnimation(view3dInstance, setAnim, isAnimating);
      }
    },
    resetCamera: (view3dInstance, setAnim, isAnimating) => {
      if (view3dInstance) {
        ResetCamera(view3dInstance, setAnim, isAnimating, true, 1200);
      }
    },
    showAnnotations: (view3dInstance) => {
      if (view3dInstance) {
        ShowAllAnnotations(view3dInstance);
      }
    },
    nextAnnotation: (view3dInstance, currentSelectedIndex, annotationList = []) => {
      if (view3dInstance && annotationList.length > 0) {
        currentSelectedIndex =
          (currentSelectedIndex + 1) % annotationList.length;
        view3dInstance.annotation.list[currentSelectedIndex].element.click();
      }
      return currentSelectedIndex;
    },
    prevAnnotation: (view3dInstance, currentSelectedIndex, annotationList = []) => {
      if (view3dInstance && annotationList.length > 0) {
        currentSelectedIndex =
          (currentSelectedIndex - 1 + annotationList.length) %
          annotationList.length;
        view3dInstance.annotation.list[currentSelectedIndex].element.click();
      }
      return currentSelectedIndex;
    },
    selectCurrentAnnotation: (
      view3dInstance,
      currentSelectedIndex,
      annotationList = []
    ) => {
      if (view3dInstance && annotationList.length > 0) {
        view3dInstance.annotation.list[currentSelectedIndex].element.click();
      }
      return currentSelectedIndex;
    },
  };
};
export const sleeper = (ms) => {
  return function (x) {
    return new Promise((resolve) => setTimeout(() => resolve(x), ms));
  };
};
