import React from "react";
import DisplayCSS from "./Display.module.css";
import ToolsSection from "./ToolsSection/ToolsSection";

function Display() {
  return (
    <div className={DisplayCSS.display}>
      <img src="/images/image.png" alt="" />
      <ToolsSection />
    </div>
  );
}

export default Display;
